import React from 'react';
import Layout from '../components/layout';
import DisclosureTemplate from '../templates/disclosure-template';
import SEO from '../components/seo';
import { graphql } from 'gatsby';

export const query = graphql`
	{
		allMarkdownRemark(
			filter: { frontmatter: { slug: { eq: "disclosures" } } }
		) {
			nodes {
				frontmatter {
					description
					mast {
						title
						image
					}
				}
				rawMarkdownBody
			}
		}
	}
`;

export default ({ data }) => {
	const content = data.allMarkdownRemark.nodes[0];
	return (
		<Layout page="Disclosures">
			<SEO
				title="Disclosures"
				description={data.allMarkdownRemark.nodes[0].frontmatter.description}
			/>
			<DisclosureTemplate
				mast={content.frontmatter.mast}
				content={content.rawMarkdownBody}
			/>
		</Layout>
	);
};
